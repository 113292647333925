import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'

import routes from './router'

import vuetify from './plugins/vuetify'

// import VueRouter from 'vue-router'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

// router stuff
const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

const app = createApp(App)

app.use(vuetify)
app.use(router)

// set up log method everywhere
app.config.globalProperties.$log = console.log

app.mount('#app')
