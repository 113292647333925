<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer v-model="drawer" permanent>
        <v-list density="compact" nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            :title="item.title"
            :value="item.title"
            :prepend-icon="item.icon"
            :color="item.iconColor"
            link
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main style="height: 350px"> </v-main>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: 'NavigationCard',

  data() {
    return {
      //#D02090 #FF00FF #FF1493 #FF69B4 #FFB6C1
      drawer: true,
      items: [
        { title: 'Home', iconColor: '#D02090', icon: 'mdi-home-assistant', path: '/' },
        { title: 'About', iconColor: '#FF1493', icon: 'mdi-axis-arrow-info', path: '/about' },
        {
          title: 'Training',
          iconColor: '#FF00FF',
          icon: 'mdi-account-star-outline',
          path: '/training',
        },
        {
          title: 'Inspiration',
          iconColor: '#FF1493',
          icon: 'mdi-head-snowflake-outline',
          path: '/inspiration',
        },
        { title: 'Payments', iconColor: '#FF69B4', icon: 'mdi-credit-card-outline', path: '/pay' },
        {
          title: 'Terms & Conditions',
          iconColor: '#FFB6C1',
          icon: 'mdi-file-document-outline',
          path: '/terms',
        },
        {
          title: 'Privacy',
          iconColor: '#FFB6C1',
          icon: 'mdi-security',
          path: '/privacy',
        },
      ],
      rail: false,
    }
  },

  methods() {},
}
</script>
<style></style>
