const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/MainView.vue'),
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/inspiration',
    name: 'InspirationView',
    component: () => import('../views/InspirationView.vue'),
  },

  {
    path: '/terms',
    name: 'Terms & Conditions',
    component: () => import('../views/TermsView.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyView.vue'),
  },
  {
    path: '/pay',
    name: 'Payments',
    component: () => import('../views/PaymentView.vue'),
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/TrainingView.vue'),
  },
  {
    path: '/code',
    name: 'Coding',
    component: () => import('../views/CodeMirrorView.vue'),
  },
]

export default routes
