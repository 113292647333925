<template>
  <v-app>
    <v-navigation-drawer v-model="drawer">
      <NavigationCard />
    </v-navigation-drawer>
    <v-app-bar flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img class="mx-2" :src="require('./assets/logo_inline.svg')" max-width="300"></v-img>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <FooterSection />
  </v-app>
</template>

<script>
import FooterSection from './components/FooterSection.vue'
import NavigationCard from './components/NavigationCard.vue'

export default {
  name: 'App',

  components: {
    FooterSection,
    NavigationCard,
  },

  data: () => ({
    drawer: false,
  }),
}
</script>
