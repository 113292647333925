<template>
  <v-footer class="d-flex flex-column w-100">
    <div class="pink-text footer-text d-flex w-100 align-center px-4">
      &copy; {{ new Date().getFullYear() }} TTC Ltd.

      <v-spacer></v-spacer>
      <a href="/#/terms">terms & conditions</a> <a href="/#/privacy">privacy policy</a>
      <v-btn
        v-for="icon in icons"
        :key="icon"
        class="mx-2"
        :icon="icon"
        variant="plain"
        size="small"
      ></v-btn>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterSection',

  data: () => ({
    icons: ['mdi-linkedin'],
  }),
}
</script>

<style>
@import '../assets/styles/main.css';
</style>
